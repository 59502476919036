import React, { useEffect, useRef, useState } from "react";
import Bg1 from "./assets/img/bg1.jpg";
import Logo from "./assets/img/logo.png";
import Img1 from "./assets/img/img1.jpg";
import Img2 from "./assets/img/img2.jpg";
import Map from "./assets/img/map.png";
import ContactImg from "./assets/img/contact.png";
import PinImg from "./assets/img/pin.png";
import Logofactory1 from "./assets/img/logofactory/1.jpg";
import Logofactory2 from "./assets/img/logofactory/2.jpg";
import Logofactory3 from "./assets/img/logofactory/3.png";
import Logofactory4 from "./assets/img/logofactory/4.png";
import Logofactory5 from "./assets/img/logofactory/5.png";
import Logofactory6 from "./assets/img/logofactory/6.png";
import Logofactory7 from "./assets/img/logofactory/7.png";
import Logofactory8 from "./assets/img/logofactory/8.jpeg";
import Logofactory9 from "./assets/img/logofactory/9.png";
import Logofactory10 from "./assets/img/logofactory/10.jpg";
import Logofactory11 from "./assets/img/logofactory/11.png";
import Logofactory12 from "./assets/img/logofactory/12.png";
import Logofactory13 from "./assets/img/logofactory/13.jpg";
import Logofactory14 from "./assets/img/logofactory/14.png";
import Logofactory15 from "./assets/img/logofactory/15.png";
import Logofactory16 from "./assets/img/logofactory/16.png";
import Logofactory17 from "./assets/img/logofactory/17.png";
import Logofactory18 from "./assets/img/logofactory/18.jpg";
import Logofactory19 from "./assets/img/logofactory/19.png";
import Logofactory20 from "./assets/img/logofactory/20.png";
import Logofactory21 from "./assets/img/logofactory/21.png";
import Logofactory22 from "./assets/img/logofactory/22.png";
import Logofactory23 from "./assets/img/logofactory/23.png";
import Logofactory24 from "./assets/img/logofactory/24.gif";
import Logofactory25 from "./assets/img/logofactory/25.png";
import Logofactory26 from "./assets/img/logofactory/26.png";
import Logofactory27 from "./assets/img/logofactory/27.png";
import Logofactory28 from "./assets/img/logofactory/28.jpg";

import ServiceImg from "./assets/img/serviceX2.jpg";
import "./App.css";
import { Carousel } from "antd";

export default function Homepage() {
  const [state, setstate] = useState("หน้าแรก");
  const myRef = useRef(null);
  let LogofactoryArray = [
    Logofactory1,
    Logofactory2,
    Logofactory3,
    Logofactory4,
    Logofactory5,
    Logofactory6,
    Logofactory7,
    Logofactory8,
    Logofactory9,
    Logofactory10,
    Logofactory11,
    Logofactory12,
    Logofactory13,
    Logofactory14,
    Logofactory15,
    Logofactory16,
    Logofactory17,
    Logofactory18,
    Logofactory19,
    Logofactory20,
    Logofactory21,
    Logofactory22,
    Logofactory23,
    Logofactory24,
    Logofactory25,
    Logofactory26,
    Logofactory27,
    Logofactory28,
  ];
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  // const onScroll = (e) => {
  //   setScrollTop(e.target.documentElement.scrollTop);
  //   setScrolling(e.target.documentElement.scrollTop > scrollTop);
  // }

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop >= 0 && scrollTop < 780) {
      setstate("หน้าแรก");
    }
    if (scrollTop >= 780 && scrollTop < 2300) {
      setstate("เกี่ยวกับเรา");
    }
    if (scrollTop >= 2300 && scrollTop < 3500) {
      setstate("บริการของเรา");
    }
    if (scrollTop >= 3500 && scrollTop < 3912) {
      setstate("ลูกค้าของเรา");
    }
    if (scrollTop >= 3912) {
      setstate("ติดต่อเรา");
    }
  }, [scrollTop]);

  function onChange(a, b, c) {
    console.log(a, b, c);
  }

  return (
    <div>
      <section>
        <img
          src={Bg1}
          alt="mpcBG"
          width="100%"
          style={{ position: "absolute", zIndex: -1, objectFit: "cover" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            alignItems: "center",
            position: "fixed",
            width: "100%",
            zIndex: "100",
          }}
        >
          <div style={{ width: "10%" }}>
            <a
              href="#home"
              onClick={() => {
                setstate("หน้าแรก");
              }}
            >
              <img
                src={Logo}
                alt="mpcLogo"
                width="100%"
                style={{ objectFit: "cover", cursor: "pointer" }}
              />
            </a>
          </div>
          {/* <div>
            {scrolling + ""} {scrollTop}
          </div> */}

          <div
            key={"menu"}
            style={{
              display: "flex",
              flexDirection: "row",
              paddingRight: "20px",
            }}
          >
            <a href="#home" style={{ textDecoration: "none" }}>
              <div
                className={
                  (state === "หน้าแรก"
                    ? "selectedHeaderButton"
                    : "headerButton") + " font2vw"
                }
              >
                หน้าแรก
              </div>
            </a>
            <a href="#about" style={{ textDecoration: "none" }}>
              <div
                className={
                  (state === "เกี่ยวกับเรา"
                    ? "selectedHeaderButton"
                    : "headerButton") + " font2vw"
                }
              >
                เกี่ยวกับเรา
              </div>
            </a>
            <a href="#service" style={{ textDecoration: "none" }}>
              <div
                className={
                  (state === "บริการของเรา"
                    ? "selectedHeaderButton"
                    : "headerButton") + " font2vw"
                }
              >
                บริการของเรา
              </div>
            </a>
            <a href="#customer" style={{ textDecoration: "none" }}>
              <div
                className={
                  (state === "ลูกค้าของเรา"
                    ? "selectedHeaderButton"
                    : "headerButton") + " font2vw"
                }
              >
                ลูกค้าของเรา
              </div>
            </a>
            <a href="#contact" style={{ textDecoration: "none" }}>
              <div
                className={
                  (state === "ติดต่อเรา"
                    ? "selectedHeaderButton"
                    : "headerButton") + " font2vw"
                }
              >
                ติดต่อเรา
              </div>
            </a>
          </div>
        </div>
      </section>
      <section ref={myRef} id="home" className="sectionHome">
        <div className="imgSectionHome" style={{ margin: "2%" }}>
          <img
            src={Img1}
            className="boxShadow"
            alt="mpcLogo"
            width="100%"
            style={{ objectFit: "cover", borderRadius: "30px" }}
          />
        </div>
        <div style={{ width: "40%" }}>
          <p className="font3dot5vw" style={{ cursor: "pointer" }}>
            Mechanic Professional <br />
            co.,ltd
          </p>
        </div>
      </section>
      <section id="about">
        <div
          className="sectionAbout"
          style={{
            width: "100%",
            backgroundImage: "url(" + Img2 + ")",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(210,48,44,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={Logo}
              alt="mpcLogo"
              className="imgSectionAbout"
              style={{ objectFit: "cover", cursor: "pointer" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p className="font2dot5vw" style={{ textAlign: "center" }}>
            <span>บริษัท</span>
            <br />
            <span className="font3dot5vw">เมคคานิก โปรเฟสชั่นแนล</span> จำกัด
          </p>
          <p className="aboutText ">
            เริ่มก่อตั้งเมื่อปี พ.ศ. 2550 ด้วยทุนจดทะเบียน 1,000,000 บาท
            ก่อตั้งขึ้นมาเพื่อให้บริการด้าน งานติดตั้ง งานรับซ่อม ถอด
            ประกอบเครื่องจักรกลอุตสาหกรรมขนาดเล็กไปจนถึงขนาดใหญ่ทุกประเภท
            งานระบบท่อ ปั๊ม วาล์ว งานขนย้ายไลน์ผลิต ทั้งภายในและภายนอกโรงงาน
            รับตรวจสอบ ปรับปรุง บำรุงรักษาเครื่องจักรอุตสาหกรรม
            และงานโครงสร้างต่างๆ เป็นต้น{" "}
          </p>
          <p className="aboutText ">
            องค์กรได้สร้างผลงานที่เต็มเปี่ยมไปด้วยประสิทธิภาพมุ่งเน้นถึงการส่งมอบงานทันตามเวลาที่ลูกค้าต้องการ
            มีความซื่อสัตย์ จริงใจ
            ต่อลูกค้าโดยการันตีได้จากการที่ลูกค้าเก่าของเราเรียกเรากลับมาใช้งานอีกครั้งเสมอ
            ตลอดถึงการเอาใจใส่ทุกรายละเอียดสำคัญเพื่อให้ลูกค้าได้รับความพึงพอใจสูงสุดจึงทำให้บริษัท
            ฯ
            เป็นหนึ่งในบริษัทที่มีชื่อเสียงเป็นที่ยอมรับของลูกค้าและบุคคลในวงการทางวิศวกรรมมายาวนาน
          </p>
        </div>
      </section>
      <section id="service">
        <div style={{ width: "100%" }}>
          <img
            src={ServiceImg}
            alt="ServiceImg"
            width="100%"
            style={{ objectFit: "cover" }}
          />
        </div>
      </section>
      <section id="customer" style={{ backgroundColor: "#201C1C" }}>
        <p
          className="font3vw"
          style={{
            textAlign: "center",
            textDecoration: "underline",
            color: "white",
          }}
        >
          ลูกค้าของเรา
        </p>
        <div className="divCarouselCustomer">
          <Carousel
            afterChange={onChange}
            autoplay
            dots={{ className: "dotsCarouselCustomer" }}
          >
            <div>
              <div className="divboxCarouselCustomer">
                {[0, 1, 2, 3, 4, 5].map((data, i) => {
                  return (
                    <div className={"FactoryBox boxShadow"} key={i}>
                      <img
                        src={LogofactoryArray[data]}
                        alt="FactoryBox"
                        className={"FactoryBox-img"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="divboxCarouselCustomer">
                {[6, 7, 8, 9, 10, 11].map((data, i) => {
                  return (
                    <div className={"FactoryBox boxShadow"} key={i}>
                      <img
                        src={LogofactoryArray[data]}
                        alt="FactoryBox"
                        className={"FactoryBox-img"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="divboxCarouselCustomer">
                {[12, 13, 14, 15, 16, 17].map((data, i) => {
                  return (
                    <div className={"FactoryBox boxShadow"} key={i}>
                      <img
                        src={LogofactoryArray[data]}
                        alt="FactoryBox"
                        className={"FactoryBox-img"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="divboxCarouselCustomer">
                {[18, 19, 20, 21, 22, 23].map((data, i) => {
                  return (
                    <div className={"FactoryBox boxShadow"} key={i}>
                      <img
                        src={LogofactoryArray[data]}
                        alt="FactoryBox"
                        className={"FactoryBox-img"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <div className="divboxCarouselCustomer">
                {[24, 25, 26, 27].map((data, i) => {
                  return (
                    <div className={"FactoryBox boxShadow"} key={i}>
                      <img
                        src={LogofactoryArray[data]}
                        alt="FactoryBox"
                        className={"FactoryBox-img"}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </Carousel>
        </div>
      </section>
      <section
        id="contact"
        style={{ backgroundColor: "white", paddingBottom: 30 }}
      >
        <p
          className="font3vw"
          style={{ textAlign: "center", textDecoration: "underline" }}
        >
          ติดต่อเรา
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={Map} alt="Map" className="sizemap" />
        </div>
      </section>
      <section id="contact" className="sectionContact">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <img
            src={PinImg}
            alt="PinImg"
            className="imgSectionContact"
            style={{ objectFit: "contain" }}
          />
          <div className="ContactText" style={{ paddingLeft: "20px" }}>
            38/46 ม.14 ตำบลบึงคำพร้อย อำเภอลำลูกกา จังหวัดปทุมธานี  12150
            <br />
            38/46 Moo. 14, Bueng Kham Phroi, Lam Luk Ka, Phathumtani, 12150
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          <img
            src={ContactImg}
            alt="ContactImg"
            className="imgSectionContact"
            style={{ objectFit: "contain" }}
          />
          <div className="ContactText" style={{ paddingLeft: "20px" }}>
            Tel : 061-9601565  Fax : 02-0389534
            <br />
            E-mail : promech12@hotmail.co.th www.mechanicmpc.com
          </div>
        </div>
      </section>
    </div>
  );
}
